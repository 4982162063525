
import * as _ref_Li9ndC1lbnYuanM_ from './gt-env.js';
import * as Li9za2lrby5tanM from './skiko.mjs';
import * as _ref_QGF6dXJlL21zYWwtYnJvd3Nlcg_ from '@azure/msal-browser';
import { instantiate } from './web.uninstantiated.mjs';


const exports = (await instantiate({
    './gt-env.js': _ref_Li9ndC1lbnYuanM_,
    './skiko.mjs': Li9za2lrby5tanM,
    '@azure/msal-browser': _ref_QGF6dXJlL21zYWwtYnJvd3Nlcg_
})).exports;

export const {
    memory,
    _initialize,
    startUnitTests
} = exports;

